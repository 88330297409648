import { Fragment, useEffect, useState } from 'react';
import Router from 'next/router';
import { Badge, Col, Dropdown, Grid, Menu, Row, Space, Typography } from 'antd';
import classNames from 'classnames';

import Image from 'src/components/Image';
import useTotalNotificationCount from 'src/hooks/useTotalNotificationCount';

import DrawerNotification from '../TabMenuNotification/DrawerNotification';

import classes from './UserMessageMenu.module.less';

const messageMenus = [
	{
		id: 0,
		img: '/icons/quick-menu/konsultasi.svg',
		text: 'Konsultasi',
		link: '/tanya-dokter/konsultasi',
		count: 0,
	},
	{
		id: 1,
		img: '/icons/quick-menu/janji-medis.svg',
		text: 'Janji Medis',
		link: '/janji-medis/konsultasi',
		count: 0,
	},
];

type Props = {
	type: 'dropdown' | 'drawer';
	setVisible?: (v: boolean) => void;
};

const UserMessagMenuItem = (props: Props) => {
	const { type, setVisible } = props;

	const onClickMenu = (link: string) => {
		if (link) Router.push(link);
		if (setVisible) setVisible(false);
	};

	return (
		<Menu
			className={classNames({
				[classes['user-message-dropdown-menu']]: type === 'dropdown',
				[classes['user-message-drawer-menu']]: type === 'drawer',
			})}
		>
			{messageMenus?.map((menu) => {
				switch (type) {
					case 'dropdown':
						return (
							<Fragment key={menu.id}>
								<Menu.Item className={classes.item} onClick={() => onClickMenu(menu.link)}>
									<Row justify="space-between">
										<Col>
											<Space size={12}>
												<Image
													src={menu.img}
													height={24}
													width={24}
													alt={menu.text}
													objectFit="cover"
												/>
												<Typography.Text className={classes.text}>{menu.text}</Typography.Text>
											</Space>
										</Col>
										<Col>
											<Badge className={classes.badge} count={menu.count}></Badge>
										</Col>
									</Row>
								</Menu.Item>
							</Fragment>
						);
					case 'drawer':
						return (
							<Fragment key={menu.id}>
								<Menu.Item className={classes.item} onClick={() => onClickMenu(menu.link)}>
									<Row justify="space-between">
										<Col>
											<Space size={12}>
												<Image
													src={menu.img}
													height={24}
													width={24}
													alt={menu.text}
													objectFit="cover"
												/>
												<Typography.Text className={classes.text}> {menu.text} </Typography.Text>
											</Space>
										</Col>
										<Col>
											<Space size={16}>
												<Badge className={classes.badge} count={menu.count}></Badge>
												<Image
													src="/icons/arrow-chevron-right-gray.svg"
													height={16}
													width={16}
													alt="arrow-chevron-right"
												/>
											</Space>
										</Col>
									</Row>
								</Menu.Item>
								<Menu.Divider className={classes.divider} />
							</Fragment>
						);
					default:
						return <></>;
				}
			})}
		</Menu>
	);
};

const UserMessageMenu = (props: any) => {
	const { xs } = Grid.useBreakpoint();
	const [currentTop, setCurrentTop] = useState<number>(0);
	const totalNotif = useTotalNotificationCount();

	const hiddenBackIcon = xs ? true : false;
	const iconSize = xs ? 20 : 24;

	useEffect(() => {
		const headerHeight = xs ? 120 : 85;
		const idealTop = headerHeight - document.documentElement.scrollTop;
		setCurrentTop(idealTop);
	}, [props.visibleComponent, xs]);

	return (
		<div className={classes['user-message-menu']}>
			{xs ? (
				<>
					<div
						onClick={() =>
							props.visibleComponent != 'message'
								? props.setVisibleComponent('message')
								: props.setVisibleComponent('')
						}
					>
						<Badge className={classes.badge} offset={[-1, 1]} count={totalNotif.message}>
							<Image
								width={iconSize}
								height={iconSize}
								alt="Message"
								src="/icons/message.svg"
								layout="fixed"
							/>
						</Badge>
					</div>
					<DrawerNotification
						classesDrawers={classes['user-message-drawer']}
						textHeader="Pesan"
						placement={'top'}
						showingHeader={true}
						showingLogo={false}
						visibleDrawer={props.visibleComponent == 'message'}
						paddingTopDrawer={currentTop}
						setVisibleDrawerClose={() => props.setVisibleComponent('')}
						hiddenBackIcon={hiddenBackIcon}
					>
						<UserMessagMenuItem type="drawer" setVisible={props.setVisibleComponent} />
					</DrawerNotification>
				</>
			) : (
				<Dropdown
					overlayClassName={classes['user-message-dropdown']}
					overlay={UserMessagMenuItem({ type: 'dropdown' })}
					trigger={['click']}
					placement="bottomRight"
					arrow
					getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
				>
					<Badge className={classes.badge} offset={[-1, 1]} count={totalNotif.message}>
						<Image
							width={iconSize}
							height={iconSize}
							alt="Message"
							src="/icons/message.svg"
							layout="fixed"
						/>
					</Badge>
				</Dropdown>
			)}
		</div>
	);
};

export default UserMessageMenu;
