import { Col, Grid, Row } from 'antd';
import classNames from 'classnames';

import UserAvatarMenu from './UserAvatarMenu';
import UserMessageMenu from './UserMessageMenu';
import UserNotificationMenu from './UserNotificationMenu';

import classes from './index.module.less';

const UserMenuC = (props: any) => {
	const { xs, sm, md, lg } = Grid.useBreakpoint();
	const responsive = (xs || sm || md) && !lg;

	return (
		<Row
			align="middle"
			justify="end"
			wrap={false}
			className={`${classes['quick-menu']} ${classNames({
				'ml-3': responsive,
			})}`}
			style={{ minWidth: 'fit-content' }}
		>
			<Col onClick={() => !xs && props.setVisibleComponent('')}>
				<div className={classes['menu-item']}>
					<UserMessageMenu
						visibleComponent={props.visibleComponent}
						setVisibleComponent={props.setVisibleComponent}
					/>
				</div>
			</Col>
			<Col>
				<div className={classes['menu-item']}>
					<UserNotificationMenu
						visibleComponent={props.visibleComponent}
						setVisibleComponent={props.setVisibleComponent}
					/>
				</div>
			</Col>
			<Col onClick={() => props.setVisibleComponent('')}>
				<div className={classes['menu-item']}>
					<UserAvatarMenu />
				</div>
			</Col>
		</Row>
	);
};

export default UserMenuC;
